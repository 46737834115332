import React, { useEffect } from "react";

const ZendeskChat = () => {
  useEffect(() => {
    // Create and append the script tag for the Zendesk widget
    const script = document.createElement("script");
    script.id = "ze-snippet";
    script.src = "https://static.zdassets.com/ekr/snippet.js?key=44e882ff-79e4-4c0d-93de-448da8a098c9";
    document.head.appendChild(script);

    // Cleanup function to remove the script when the component is unmounted
    return () => {
      if (document.getElementById("ze-snippet")) {
        document.getElementById("ze-snippet")?.remove();
      }
    };
  }, []); // Empty dependency array means this effect runs once on mount

  return null; // This component does not render any visible content
};

export default ZendeskChat;
