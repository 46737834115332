import { Input, theme } from "@upsolve/ui";
import React from "react";
import styled from "styled-components";

export const CurrencyInput: React.FC<any> = (props) => {
  return (
    <StyledCurrencyInput>
      <CurrencySymbol>$</CurrencySymbol>
      <Input type="text" inputMode="numeric" pattern="[0-9]*" {...props} />
    </StyledCurrencyInput>
  );
};

const StyledCurrencyInput = styled.div`
  position: relative;
  input {
    outline: 0;
    position: relative;
    z-index: 1;
    padding-left: 1.4em;
  }
  span {
    color: ${theme.colors.gray[900]};
  }
`;

const CurrencySymbol = styled.span`
  position: absolute;
  top: 50%;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0.75em;
  font-size: 13px;
`;
