import { H4, H3, Button, theme, H5, ButtonLink } from "@upsolve/ui";
import React, { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import ThumbsUp from "./ThumbsUp.svg";
import ThumbsDown from "./ThumbsDown.svg";
import QuestionMark from "./QuestionMark.svg";
import { EligibilityResult, getStatusQuoCompoundInterest, getStatusQuoRepaymentInfo } from "./eligibilityFunctions";
import ZendeskChat from "./ZendeskChat";
import { DetailCard } from "./DetailCard";
import { formatMonths } from "./formatMonths";

//TODO: possibly consolidate with back button
const RecomputeButton = styled.button`
  color: #777e84;
  font-size: 12px;
  border-radius: 4px;
  padding: 8px 8px 8px 4px;
  cursor: pointer;

  margin: 8px auto 0;
  background-color: transparent;
  border: 2px solid #bababa;
  width: 40vw;
  max-width: 300px;
`;

const LearnMoreButton = styled.button`
  color: #777e84;
  font-size: 12px;
  font-weight: 300;
  border-radius: 4px;
  padding: 8px 8px 8px 4px;
  cursor: pointer;

  margin: 0 auto;
  background-color: transparent;
  border: 1px solid #bababa;
  width: 100%;
`;

export type TOptionInfo = {
  title: "Debt Management" | "Bankruptcy" | "Debt Settlement" | "Consolidation";
  description: string;
  isEligible: boolean | undefined;
  goodForStatement: string;
  actions: React.ReactNode;
  totalPayment: number;
  monthsInRepayment: number;
  savings: number;
  interest: number;
  fees: number;
  timeToApply: string;
  debtTimelineBreakdown: { fieldName: string; duration: string; details?: string };
  successRate: number;
  canAfford: boolean | undefined;
  monthlyPayment: number;
  notEligibleMessage: string | undefined;
  savingsBreakdown: { item: string; value: string }[];
  canAffordMessage: ReactNode;
};

// TODO: add savings amount to and consolidation to props.
export const ResultsComparison = (props: {
  onRecompute: () => void;
  unsecuredDebtEstimate: number;
  availableMonthlyFunds: number;
  dmpEligibilityResults: EligibilityResult;
  bankruptcyEligibilityResults: EligibilityResult;
  settlementEligibilityResults: EligibilityResult;
  consolidationEligibilityResults: EligibilityResult;
  trackStepAction: (actionId: string) => void;
}) => {
  const [detailViewOption, setDetailViewOption] = useState<
    null | "Debt Management" | "Bankruptcy" | "Debt Settlement" | "Consolidation"
  >(null);

  const onClickLearnMore = (option: "Debt Management" | "Bankruptcy" | "Debt Settlement" | "Consolidation") => {
    props.trackStepAction(`details.opened.${option.replace(" ", "_")}`);
    setDetailViewOption(option);
  };

  // goal of this is to track how many users qualified for which thing. I think the simplest way is to only track which qualified.
  useEffect(() => {
    if (props.dmpEligibilityResults.isEligible) props.trackStepAction("isEligible.dmp");
    if (props.bankruptcyEligibilityResults.isEligible) props.trackStepAction("isEligible.bankruptcy.upsolve");
    if (props.settlementEligibilityResults.isEligible) props.trackStepAction("isEligible.settlement");
    if (props.consolidationEligibilityResults.isEligible) props.trackStepAction("isEligible.settlement");
  }, []);

  const statusQuoInterest = getStatusQuoCompoundInterest(props.unsecuredDebtEstimate, props.availableMonthlyFunds);
  const statusQuoPaymentInfo = getStatusQuoRepaymentInfo(props.unsecuredDebtEstimate, props.availableMonthlyFunds);

  const resultCardInfo: TOptionInfo[] = [
    {
      title: "Bankruptcy",
      isEligible: props.bankruptcyEligibilityResults.isEligible,
      description: "Eliminate your debt by filing for bankruptcy in the U.S. court system.",
      goodForStatement: "Individuals with overwhelming debt who need a fresh start.",
      actions: (
        <>
          {props.bankruptcyEligibilityResults.isEligible && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                // TODO: possibly integrate with getCTAData (?)
                //  in other CTAs, we use "CTA.myUpsolveNavigation". this change will affect clickrates on existing charts. I'm ok with this because we already have multiple products on my.upsolve.org. but we may need to update those charts.
                props.trackStepAction("CTA.bankruptcy.screener");
                window.open("https://my.upsolve.org/bankruptcy-screener");
              }}
            >
              Explore Free Tool
            </Button>
          )}
          <Button
            inverted
            onClick={(e) => {
              e.stopPropagation();
              props.trackStepAction("CTA.bankruptcy.referral");
              window.open("https://my.upsolve.org/referral");
            }}
          >
            Talk to a Lawyer
          </Button>
        </>
      ),
      totalPayment: props.bankruptcyEligibilityResults.totalPayment,
      monthsInRepayment: props.bankruptcyEligibilityResults.monthsInRepayment,
      savings: props.bankruptcyEligibilityResults.savings,
      interest: props.bankruptcyEligibilityResults.interest,
      fees: props.bankruptcyEligibilityResults.fees,
      debtTimelineBreakdown: {
        fieldName: "Typical timeline",
        duration: "3-4 months",
        details: `If you have a successful bankruptcy filing and receive a discharge, dischargeable debts will be cleared. The process typically takes 3-4 months, but can vary based on a filer's specific circumstances.`,
      },
      timeToApply: "3-4 hours",
      successRate: 97,
      canAfford: props.bankruptcyEligibilityResults.canAfford,
      monthlyPayment: props.bankruptcyEligibilityResults.monthlyPayment,
      notEligibleMessage: props.bankruptcyEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        { item: "- eliminated in bankruptcy", value: `- $${props.unsecuredDebtEstimate.toLocaleString()}` },
        { item: "+ filing fees", value: `+ $${props.bankruptcyEligibilityResults.fees}` },
        {
          item: props.bankruptcyEligibilityResults.isEligible === undefined ? "+ lawyer fees" : "+ filing with Upsolve",
          value: props.bankruptcyEligibilityResults.isEligible === undefined ? "+ $2000" : "+ $0",
        },
      ],
      canAffordMessage: (
        <div>
          {props.bankruptcyEligibilityResults.isEligible && (
            <>
              Filing with Upsolve is free. You may have to pay the court's $338 filing fee.
              <br />
              <br />
            </>
          )}
          Filing with a lawyer costs $2,000-$4,000 on average.
        </div>
      ),
    },
    {
      title: "Debt Management",
      isEligible: props.dmpEligibilityResults.isEligible,
      description: "Reduce your interest rates by enrolling in a creditor-sponsored program.",
      goodForStatement: "Individuals with a steady income who want to reduce their interest rates.",
      canAfford: props.dmpEligibilityResults.canAfford,
      actions: (
        <Button
          disabled={!props.dmpEligibilityResults.isEligible}
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.DMP");
            window.open("https://calendly.com/camupsolve-appt/upsolve-appointment");
          }}
        >
          Get Started
        </Button>
      ),
      totalPayment: props.dmpEligibilityResults.totalPayment,
      monthlyPayment: props.dmpEligibilityResults.monthlyPayment,
      savings: props.dmpEligibilityResults.savings,
      interest: props.dmpEligibilityResults.interest,
      fees: props.dmpEligibilityResults.fees,
      debtTimelineBreakdown: {
        fieldName: "Estimated timeline",
        duration: formatMonths(props.dmpEligibilityResults.monthsInRepayment),
      },
      timeToApply: "3-4 hours",
      successRate: 80,
      monthsInRepayment: props.dmpEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.dmpEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        { item: "+ estimated interest at 8%", value: `+ $${props.dmpEligibilityResults.interest.toLocaleString()}` },
        { item: "+ estimated fees", value: `+ $${props.dmpEligibilityResults.fees.toLocaleString()}` },
      ],
      canAffordMessage: props.dmpEligibilityResults.canAfford ? (
        <div>
          If you pay your available funds of ${props.availableMonthlyFunds.toLocaleString()} each month, this option
          makes sense.
        </div>
      ) : (
        <div>
          Your available monthly funds of ${props.availableMonthlyFunds.toLocaleString()} won't cover the interest on a
          Debt Management Plan.
        </div>
      ),
    },
    {
      title: "Debt Settlement",
      isEligible: props.settlementEligibilityResults.isEligible,
      description: "Reduce your debt balances by negotiating reductions with your creditors.",
      goodForStatement:
        "People who are more than 90 days past due on at least one debt, and have enough savings to pay down half of their debt in a lump sum.",

      canAfford: props.settlementEligibilityResults.canAfford,
      actions: (
        <Button
          inverted={!props.settlementEligibilityResults.isEligible}
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.Settlement");
            window.open("https://upsolve.org/learn/debt-settlement");
          }}
        >
          Learn More
        </Button>
      ),
      totalPayment: props.settlementEligibilityResults.totalPayment,
      monthlyPayment: props.settlementEligibilityResults.monthlyPayment,
      savings: props.settlementEligibilityResults.savings,
      interest: props.settlementEligibilityResults.interest,
      fees: props.settlementEligibilityResults.fees,
      debtTimelineBreakdown: {
        fieldName: "Typical timeline",
        duration: props.settlementEligibilityResults.isEligible ? "1-2 months" : "N/A",
      },
      timeToApply: props.settlementEligibilityResults.isEligible ? "3-4 hours" : "N/A",
      successRate: 50,
      monthsInRepayment: props.settlementEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.settlementEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        {
          item: "+ estimated interest at 25%",
          value: `+ $${props.settlementEligibilityResults.interest.toLocaleString()}`,
        },
        {
          item: "- eliminated in settlement",
          value: `- $${props.settlementEligibilityResults.savings.toLocaleString()}`,
        },
      ],
      canAffordMessage: props.settlementEligibilityResults.canAfford ? (
        <div>You have enough savings to start a settlement.</div>
      ) : (
        <div>To settle a debt, you'd need monthly income and enough savings to cover half of the debt.</div>
      ),
    },
    {
      title: "Consolidation",
      isEligible: props.consolidationEligibilityResults.isEligible,
      description: "Reduce your monthly payment and get a lower interest rate by combining multiple debts into one.",
      goodForStatement:
        "People with credit scores above 500 who can afford to make monthly payments around 75% of what they currently pay until their debt is paid in full.",
      canAfford: props.consolidationEligibilityResults.canAfford,
      actions: (
        <Button
          inverted={!props.consolidationEligibilityResults.isEligible}
          onClick={(e) => {
            e.stopPropagation();
            props.trackStepAction("CTA.Consolidation");
            window.open("https://upsolve.org/learn/debt-consolidation");
          }}
        >
          Learn More
        </Button>
      ),
      totalPayment: props.consolidationEligibilityResults.totalPayment,
      monthlyPayment: props.consolidationEligibilityResults.monthlyPayment,
      savings: props.consolidationEligibilityResults.savings,
      interest: props.consolidationEligibilityResults.interest,
      fees: props.consolidationEligibilityResults.fees,
      debtTimelineBreakdown: {
        fieldName: "Estimated timeline",
        duration: formatMonths(props.consolidationEligibilityResults.monthsInRepayment),
      },
      timeToApply: "2-3 hours",
      successRate: 100,
      monthsInRepayment: props.consolidationEligibilityResults.monthsInRepayment,
      notEligibleMessage: props.consolidationEligibilityResults.notEligibleMessage,
      savingsBreakdown: [
        {
          item: "+ estimated interest at 10%",
          value: `+ $${props.consolidationEligibilityResults.interest.toLocaleString}`,
        },
        { item: "+ estimated fees", value: `+ $${props.consolidationEligibilityResults.fees.toLocaleString()}` },
      ],
      canAffordMessage: props.consolidationEligibilityResults.canAfford ? (
        <div>
          If you pay your available funds of ${props.availableMonthlyFunds.toLocaleString()} each month, this option
          makes sense.
        </div>
      ) : (
        <div>
          Your available monthly funds of ${props.availableMonthlyFunds.toLocaleString()} won't cover the interest of
          your consolidated debt.
        </div>
      ),
    },
  ];

  if (detailViewOption) {
    const optionInfo = resultCardInfo.find((option) => option.title === detailViewOption);
    // deal with typescript later - this is always true
    if (optionInfo)
      return (
        <StyledResultsScreen>
          <DetailCard
            unsecuredDebtEstimate={props.unsecuredDebtEstimate}
            availableMonthlyFunds={props.availableMonthlyFunds}
            statusQuoInfo={{
              interest: statusQuoInterest,
              monthlyPayment: props.availableMonthlyFunds,
              monthsInRepayment: statusQuoPaymentInfo.monthsInRepayment,
              totalPayment: statusQuoPaymentInfo.totalPayment,
            }}
            onClickBack={() => {
              props.trackStepAction(`details.closed.${detailViewOption}`);
              setDetailViewOption(null);
            }}
            optionInfo={optionInfo}
          />
          <ZendeskChat />
        </StyledResultsScreen>
      );
  }

  /*
  Options are sorted in the following ways:
  1: Products we offer
  2: Eligibility
  3: Affordability
  */

  const sortedResultCardInfo = [
    ...resultCardInfo.filter((option) => option.isEligible && option.canAfford),
    ...resultCardInfo.filter((option) => option.isEligible && !option.canAfford),
    ...resultCardInfo.filter((option) => option.isEligible === undefined),
    ...resultCardInfo.filter((option) => option.isEligible === false),
  ];

  return (
    <StyledResultsScreen>
      <StyledResultsComparisonPanel>
        {sortedResultCardInfo.map((cardInfo) => (
          <ResultCard
            title={cardInfo.title}
            isEligible={cardInfo.isEligible}
            description={cardInfo.description}
            goodForStatement={cardInfo.goodForStatement}
            actions={cardInfo.actions}
            unsecuredDebt={props.unsecuredDebtEstimate}
            onClickLearnMore={() => onClickLearnMore(cardInfo.title)}
            debtTimelineBreakdown={cardInfo.debtTimelineBreakdown}
            timeToApply={cardInfo.timeToApply}
            successRate={cardInfo.successRate}
            savings={cardInfo.savings}
            notEligibleMessage={cardInfo.notEligibleMessage}
          />
        ))}
      </StyledResultsComparisonPanel>
      <ZendeskChat />
      <RecomputeButton
        onClick={() => {
          props.trackStepAction("changeAnswers");
          props.onRecompute();
        }}
      >
        ← Change Answers
      </RecomputeButton>
    </StyledResultsScreen>
  );
};

type TResultsCardProps = {
  title: string;
  isEligible: boolean | undefined;
  description: string;
  goodForStatement: string;
  actions: React.ReactNode;
  unsecuredDebt: number;
  onClickLearnMore: () => void;
  savings: number;
  debtTimelineBreakdown: { fieldName: string; duration: string };
  timeToApply: string;
  successRate: number;
  notEligibleMessage: string | undefined;
};
const ResultCard = (props: TResultsCardProps) => {
  return (
    <StyledResultsCardContainer onClick={props.onClickLearnMore}>
      <StyledThumbContainer>
        {props.isEligible ? <ThumbsUp /> : props.isEligible !== false ? <QuestionMark /> : <ThumbsDown />}
      </StyledThumbContainer>
      <StyledResultCard isEligible={props.isEligible}>
        <div className="title_section">
          <H3>{props.title}</H3>
          <div className="description">{props.description}</div>
        </div>
        {props.isEligible !== false ? (
          <div className="savings_section">
            {props.savings > 0 && (
              <div className="line_item">
                Savings: <b>${Math.round(props.savings).toLocaleString()}</b>
              </div>
            )}
            <div className="line_item">
              {props.debtTimelineBreakdown.fieldName}: <b>{props.debtTimelineBreakdown.duration}</b>
            </div>
            <div className="line_item">
              Time to apply: <b>{props.timeToApply}</b>
            </div>
            {/* <div className="line_item">
              Success Rate: <b>{props.successRate}%</b>
            </div> */}
            <LearnMoreButton
              onClick={(e) => {
                e.stopPropagation();
                props.onClickLearnMore();
              }}
            >
              Is this right for me?
            </LearnMoreButton>
          </div>
        ) : (
          <div>
            <div className="ineligible_section">
              <b className="title">{props.isEligible === false ? `Not Recommended.` : `May Be An Option.`}</b>
              <div>{props.notEligibleMessage}</div>
            </div>
            <LearnMoreButton onClick={props.onClickLearnMore}>
              {props.isEligible === false ? "More details" : "Is this right for me?"}
            </LearnMoreButton>
          </div>
        )}
        <div className="actions_section">
          <div className="button-container">{props.actions}</div>
        </div>
      </StyledResultCard>
    </StyledResultsCardContainer>
  );
};

const StyledResultsComparisonPanel = styled.div`
  display: flex;
  @media (min-width: ${(props) => props.theme.breakpoints[500]}) {
    justify-content: center;
  }
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  gap: 20px;
`;

// TODO: scroll dots
// TODO: scroll lock
// these min-height queries are kind of abusing our implied width breakpoints,
// but this is checking for SE vs non iPhone SE
// TODO: add vertical breakpoints
const StyledResultsScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  text-align: center;
  background-color: #f7f5f1;
  overscroll-behavior: contain;
  @media (min-height: ${(props) => props.theme.breakpoints[500]}) {
    gap: 50px;
  }
  .num_results_message {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 36px; //space for the close button, can remove once we decide if we actually need it
    @media (max-height: ${(props) => props.theme.breakpoints[500]}) {
      text-align: left;
      font-size: 14px;
      line-height: 18px;
    }
    font-size: 20px;
    .show_mobile_only {
      display: none;
      @media (max-width: ${(props) => props.theme.breakpoints[500]}) {
        display: inline;
        @media (min-height: ${(props) => props.theme.breakpoints[500]}) {
          display: block;
        }
      }
    }
  }
`;
const StyledResultCard = styled.div<{ isEligible: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  width: 340px;
  max-height: 500px;
  background-color: white;
  box-shadow: ${(props) => props.theme.effects.shadow[350]};
  padding: 20px;
  border-radius: 16px;
  text-align: center;
  gap: 24px;
  flex: 1;
  margin-top: -30px;
  margin-bottom: 16px;

  @media (min-height: ${(props) => props.theme.breakpoints[500]}) {
    max-height: 700px;
  }
  &:hover {
    box-shadow: ${theme.effects.shadow[350]};
  }

  .title_section {
    padding-top: 20px;
    height: 130px;
    .description {
      padding-top: 4px;
    }
  }
  .savings_section {
    text-align: start;
    width: 100%;
    margin-bottom: 0.4em;
    button {
      margin-top: 0.2em;
    }
    .line_item {
      display: flex;
      justify-content: space-between;
    }
    .line_item + .line_item {
      border-top: 1px solid ${(props) => props.theme.colors.white[300]};
      margin-top: 1px;
      padding-top: 1px;
    }
  }
  .ineligible_section {
    text-align: start;
    width: 100%;
    height: 10em;
    margin-bottom: 0.4em;
    border: 1px solid ${(props) => props.theme.colors.white[300]};
    border-radius: 8px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      text-align: center;
    }
  }
  .actions_section {
    display: flex;
    flex: 1;
    align-items: flex-end;

    .button-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
`;
const StyledThumbContainer = styled.div`
  position: relative;
  top: 10px;
`;

const StyledResultsCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
`;
