import { H2, P, Button, ButtonLink, Input, theme, TypographyCSS, H5, H4, H3 } from "@upsolve/ui";
import React, { useEffect, useState } from "react";
// import { Accordion } from "../Forms/productSelectorModalComponents/Accordion";
import { getStateFromZipcode, getStateMedianFamilyIncome } from "@upsolve/shared";
import styled from "styled-components";
import { CurrencyInput } from "./CurrencyInput";
import AnimatedEllipsis from "../../../static/images/animated-ellipsis.svg";
import {
  getBankruptcyEligibilityResults,
  getConsolidationEligibilityResults,
  getDmpEligibilityResults,
  getSettlementEligibilityResults,
  getStatusQuoCompoundInterest,
  getStatusQuoRepaymentInfo,
} from "./eligibilityFunctions";
import { ResultsComparison } from "./ResultsComparison";
import { TriageModalContent } from "../modals/TriageModal";
import { formatMonths } from "./formatMonths";

export const QuestionHeading = styled.p`
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  white-space: pre-wrap;
  margin: 0 0 10px 0;
  ${TypographyCSS}
`;

const StyledErrorBox = styled.span<{ $visible: boolean }>`
  visibility: ${(props) => (props.$visible ? "visible" : "hidden")};
  font-size: 12px;
  color: ${theme.colors.red[500]};
  margin-top: 5px;
  margin-left: 3px;
`;

const ErrorBox = (props: { hasError: boolean; children: React.ReactNode }) => {
  return <StyledErrorBox $visible={props.hasError}>{props.children}</StyledErrorBox>;
};

const Controls = styled.div`
  margin-top: 1em;
`;

type TControls = {
  onNext: () => void;
  onBack: () => void;
  onRestart: () => void;
};

export type TFormState = {
  zipcode: string;
  hasIncome: boolean | undefined;
  hasGoodCreditScore: boolean | undefined;
  unsecuredDebtEstimate: number | undefined;
  householdSize: number | undefined;
  passesMeansTest: boolean | undefined;
  availableMonthlyFunds: number | undefined;
  hasAccessToCapital: boolean | undefined;
};

export const initialFormState: TFormState = {
  zipcode: "",
  hasIncome: undefined,
  hasGoodCreditScore: undefined,
  unsecuredDebtEstimate: undefined,
  householdSize: undefined,
  passesMeansTest: undefined,
  availableMonthlyFunds: undefined,
  hasAccessToCapital: undefined,
};

export type TScreenProps = {
  controls: TControls;
  state: TFormState;
  updateState: (state: Partial<TFormState>) => void;
  trackStepAction: (actionId: string) => void;
};

const Intro = ({ controls }: TScreenProps) => {
  return (
    <TriageModalContent>
      <H2 style={{ fontFamily: "Castoro", textAlign: "center" }}>Welcome to Upsolve!</H2>
      <P>We’re a nonprofit that helps you get out of debt and take control of your financial future.</P>
      <P>
        We’re going to ask you some questions to help determine which debt relief options will be the best fit for your
        needs. This should only take 5 minutes. If you don’t know an answer, it’s OK to guess. Just do your best!
      </P>
      <P>This service is free, and your information is safe. Let’s get started!</P>
      <Controls>
        <Button onClick={() => controls.onNext()} style={{ marginBottom: "10px" }}>
          Begin
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const isZipcodeValid = (value: string) => {
  return getStateFromZipcode(value) !== undefined;
};

const ZipcodeAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const isValidZipcode = isZipcodeValid(state.zipcode);
  const [inputsAreDirty, setInputsAreDirty] = useState(false);

  const setZipcode = (value: string) => {
    setInputsAreDirty(true);
    updateState({ zipcode: value });
  };

  const onSubmit = () => {
    setInputsAreDirty(true);
    if (!isValidZipcode) {
      return;
    }
    // Do we really want to track this
    trackStepAction(`${state.zipcode}`);
    controls.onNext();
  };

  return (
    <TriageModalContent>
      <QuestionHeading>What is your zipcode?</QuestionHeading>
      <Input type="string" onChange={(e) => setZipcode(e.target.value)} value={state.zipcode} />
      <ErrorBox hasError={!isValidZipcode && inputsAreDirty}>Invalid Zipcode</ErrorBox>
      <Controls>
        <Button onClick={onSubmit} disabled={!isValidZipcode} style={{ marginBottom: "10px" }}>
          Next
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const DebtAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setDebt = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ unsecuredDebtEstimate: Number(cleanedValue) });
  };

  return (
    <TriageModalContent>
      <QuestionHeading>
        How much debt do you have from credit cards, medical bills, car loans, or pay day loans?{" "}
      </QuestionHeading>
      <P>It’s OK to guess - just do your best!</P>
      <P>Do not include student loan debt in this total.</P>
      <CurrencyInput
        onChange={(e: { target: { value: string } }) => setDebt(e.target.value)}
        value={state.unsecuredDebtEstimate || ""}
        placeholder={18000}
      />
      <Controls>
        <Button
          disabled={!state.unsecuredDebtEstimate}
          onClick={() => {
            trackStepAction(`${state.unsecuredDebtEstimate}`);
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
        >
          Next
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const HouseholdAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setHouseholdSize = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ householdSize: Number(cleanedValue) });
  };
  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>How many people are in your household?</QuestionHeading>
      <P>This includes you and all the people who rely on you for money in your house.</P>
      <Input
        type="string"
        inputMode="numeric"
        pattern="[0-9]*"
        placeholder={"2"}
        value={state.householdSize || ""}
        onChange={(e) => setHouseholdSize(e.target.value)}
      />
      <Controls>
        <Button
          disabled={!state.householdSize}
          onClick={() => {
            trackStepAction(`${state.householdSize}`);
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
        >
          Next
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const HasIncomeScreen = ({ controls, updateState, trackStepAction }: TScreenProps) => {
  const onAnswer = (answer: boolean) => {
    updateState({ hasIncome: answer });
    trackStepAction(`${answer}`);
    controls.onNext();
  };

  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Do you receive any monthly income?</QuestionHeading>
      <P>
        If you receive income from any kind of job, friends and family, investments, or government support, answer
        "yes".
      </P>
      <P>If anyone in your household receives income, answer "yes".</P>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }}>
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }}>
          No
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const MeansTestAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  if (state.zipcode === undefined) {
    return <>Error: missing zipcode</>;
  }

  if (state.householdSize === undefined) {
    return <>Error: missing household size</>;
  }

  const usState = getStateFromZipcode(state.zipcode);

  if (!usState) {
    return <>Error: could not derive state from zipcode</>;
  }

  const medianFamilyIncome = (getStateMedianFamilyIncome(usState, state?.householdSize) / 12).toLocaleString(
    undefined,
    {
      maximumFractionDigits: 0,
    }
  );

  const onAnswer = (answer: boolean) => {
    updateState({ passesMeansTest: !answer });
    trackStepAction(`${!answer}`);
    controls.onNext();
  };

  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>
        {`Is your household monthly income greater than $${medianFamilyIncome}?`}
      </QuestionHeading>
      <P>
        ${medianFamilyIncome} is the median family income in your state. If your household earns less, you may be
        eligible for certain debt options.
      </P>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }}>
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }}>
          No
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const AvailableMonthlyFundsAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  const setAvailableMonthlyFunds = (value: string) => {
    const cleanedValue = value.replace(/[^0-9\.\-]+/g, "");
    updateState({ availableMonthlyFunds: cleanedValue === "" ? undefined : Number(cleanedValue) });
  };

  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>
        How much money can you afford to spend each month to pay down your debt?
      </QuestionHeading>
      <CurrencyInput
        onChange={(e: { target: { value: string } }) => setAvailableMonthlyFunds(e.target.value)}
        value={state.availableMonthlyFunds === undefined ? "" : state.availableMonthlyFunds}
        placeholder={400}
      />
      <Controls>
        <Button
          disabled={state.availableMonthlyFunds === undefined}
          onClick={() => {
            trackStepAction(`${state.availableMonthlyFunds}`);
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
        >
          Next
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const AccessToCapitalAsk = ({ controls, updateState, state, trackStepAction }: TScreenProps) => {
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error</div>;
  }

  const onAnswer = (answer: boolean) => {
    updateState({ hasAccessToCapital: !!answer });
    trackStepAction(`${answer}`);
    controls.onNext();
  };

  // rounded to 10s place
  const halfOfDebt = Math.round((0.5 * state.unsecuredDebtEstimate) / 10) * 10;
  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Do you have access to ${halfOfDebt}?</QuestionHeading>
      <P>
        Include money you have now plus anything you think you could borrow from friends or family to repay your debt.
      </P>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }}>
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }}>
          No
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const HasGoodCreditScoreAsk = ({ controls, updateState, trackStepAction }: TScreenProps) => {
  const onAnswer = (answer: boolean) => {
    updateState({ hasGoodCreditScore: answer });
    trackStepAction(`${answer}`);
    controls.onNext();
  };

  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>Is your credit score over 700?</QuestionHeading>
      <P>If you're not sure it’s OK to guess!</P>
      <Controls>
        <Button onClick={() => onAnswer(true)} style={{ marginBottom: "10px" }}>
          Yes
        </Button>
        <Button onClick={() => onAnswer(false)} style={{ marginBottom: "10px" }}>
          No
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

const CalculatingScreen = ({ controls }: TScreenProps) => {
  useEffect(() => {
    setTimeout(() => controls.onNext(), 1000 * 2);
  }, []);
  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px", textAlign: "center" }}>
        Determining debt relief options...
      </QuestionHeading>
      <br />
      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "12px" }}>
        <AnimatedEllipsis fill={theme.colors.brand[500]} />
      </div>
    </TriageModalContent>
  );
};

const StatusQuoScreen = ({ controls, state }: TScreenProps) => {
  if (state.hasIncome === undefined) {
    return <div>Error: missing hasIncome</div>;
  }
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error: missing unsecuredDebtEstimate</div>;
  }

  if (state.availableMonthlyFunds === undefined) {
    return <div>Error: missing availableMonthlyFunds</div>;
  }

  if (state.hasGoodCreditScore === undefined) {
    return <div>Error: missing hasGoodCreditScore </div>;
  }

  if (state.householdSize === undefined) {
    return <div>Error: missing householdSize</div>;
  }

  const dmpEligibilityResults = getDmpEligibilityResults(
    state.hasIncome,
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds
  );
  const bankruptcyEligibilityResults = getBankruptcyEligibilityResults(
    state.passesMeansTest || !state.hasIncome,
    state.unsecuredDebtEstimate,
    state.zipcode,
    state.availableMonthlyFunds
  );

  const settlementEligibilityResults = getSettlementEligibilityResults(
    state.passesMeansTest || false,
    state.availableMonthlyFunds,
    state.hasAccessToCapital || false,
    state.unsecuredDebtEstimate
  );
  const consolidationEligibilityResults = getConsolidationEligibilityResults(
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds,
    state.hasGoodCreditScore
  );

  const numberOfOptions = Object.values([
    dmpEligibilityResults,
    bankruptcyEligibilityResults,
    settlementEligibilityResults,
    consolidationEligibilityResults,
  ]).filter((result) => result.isEligible).length;

  const numResultsMessage = (() => {
    if (numberOfOptions > 1)
      return (
        <>
          We found <b>{`${numberOfOptions}`} options</b> that can save you time and money.
        </>
      );
    if (numberOfOptions === 1)
      return (
        <>
          We found <b>1 option</b> that can save you time and money.
        </>
      );
    // TODO: copy
    return (
      <>
        Unfortunately, we are unable to suggest any debt reduction offerings to you at this time. You can still click
        "See Options" to learn more about the different options that were considered.
      </>
    );
  })();

  const statusQuoPaymentInfo = getStatusQuoRepaymentInfo(state.unsecuredDebtEstimate, state.availableMonthlyFunds);
  const statusQuoInterest = getStatusQuoCompoundInterest(state.unsecuredDebtEstimate, state.availableMonthlyFunds);

  return (
    <TriageModalContent>
      <QuestionHeading style={{ marginBottom: "10px" }}>If you continue paying down your debt:</QuestionHeading>
      {statusQuoPaymentInfo.canAfford ? (
        <>
          <P>
            You'll fully pay off your debt in <b>{`${formatMonths(statusQuoPaymentInfo.monthsInRepayment)}`}</b>.
          </P>
          <P>
            Over that time, you'll pay the current debt amount of
            <b> ${`${Math.round(state.unsecuredDebtEstimate).toLocaleString()}`}</b> and an additional
            <b> ${`${Math.round(statusQuoInterest).toLocaleString()}`}</b> in interest.
          </P>
        </>
      ) : (
        //TODO: better copy
        <P>
          With your current monthly payment of ${state.availableMonthlyFunds.toLocaleString()} and assuming a 25% APR
          (annual interest rate) on your debts, we calculate that you will be unable to pay off your debt + accrued
          interest.
        </P>
      )}
      <P>{numResultsMessage}</P>
      <Controls>
        <Button
          onClick={() => {
            controls.onNext();
          }}
          style={{ marginBottom: "10px" }}
        >
          See Options
        </Button>
      </Controls>
    </TriageModalContent>
  );
};

export const ResultsScreen = ({ state, controls, trackStepAction }: TScreenProps) => {
  if (state.hasIncome === undefined) {
    return <div>Error: missing hasIncome</div>;
  }
  if (state.unsecuredDebtEstimate === undefined) {
    return <div>Error: missing unsecuredDebtEstimate</div>;
  }

  if (state.availableMonthlyFunds === undefined) {
    return <div>Error: missing availableMonthlyFunds</div>;
  }

  if (state.hasGoodCreditScore === undefined) {
    return <div>Error: missing hasGoodCreditScore </div>;
  }

  if (state.householdSize === undefined) {
    return <div>Error: missing householdSize</div>;
  }

  const dmpEligibilityResults = getDmpEligibilityResults(
    state.hasIncome,
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds
  );
  const bankruptcyEligibilityResults = getBankruptcyEligibilityResults(
    state.passesMeansTest || !state.hasIncome,
    state.unsecuredDebtEstimate,
    state.zipcode,
    state.availableMonthlyFunds
  );

  const settlementEligibilityResults = getSettlementEligibilityResults(
    state.passesMeansTest || false,
    state.availableMonthlyFunds,
    state.hasAccessToCapital || false,
    state.unsecuredDebtEstimate
  );
  const consolidationEligibilityResults = getConsolidationEligibilityResults(
    state.unsecuredDebtEstimate,
    state.availableMonthlyFunds,
    state.hasGoodCreditScore
  );

  return (
    <ResultsComparison
      onRecompute={controls.onRestart}
      unsecuredDebtEstimate={state.unsecuredDebtEstimate}
      availableMonthlyFunds={state.availableMonthlyFunds}
      consolidationEligibilityResults={consolidationEligibilityResults}
      dmpEligibilityResults={dmpEligibilityResults}
      bankruptcyEligibilityResults={bankruptcyEligibilityResults}
      settlementEligibilityResults={settlementEligibilityResults}
      trackStepAction={trackStepAction}
    />
  );
};

export const screens = {
  Intro,
  ZipcodeAsk,
  DebtAsk,
  HouseholdAsk,
  HasIncomeScreen,
  MeansTestAsk,
  AvailableMonthlyFundsAsk,
  AccessToCapitalAsk,
  HasGoodCreditScoreAsk,
  CalculatingScreen,
  StatusQuoScreen,
  ResultsScreen,
};
